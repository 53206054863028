import React from 'react'
import {
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Box,
  Divider,
  Radio,
} from '@mui/material'

import {
  Props, PrivacyIssueAnswerInputField,
} from './types'

export default function NamedRadioGroupList({
  inputFields,
  answers,
  onChange,
}: Props) {
  const sortedInputFields = [ ...inputFields ].sort((a, b) => a.label.localeCompare(b.label))

  const sortOptionsByYesNoOrder = (options: PrivacyIssueAnswerInputField['privacyIssueAnswerInputFieldOptions']) => [ ...options ].sort((a, b) => {
    if (a.internalValue === 'Yes' && b.internalValue !== 'Yes') return -1
    if (a.internalValue !== 'Yes' && b.internalValue === 'Yes') return 1
    if (a.internalValue === 'No' && b.internalValue !== 'No') return -1
    if (a.internalValue !== 'No' && b.internalValue === 'No') return 1

    return 0
  })

  return (
    <Stack spacing={4}>
      {sortedInputFields.map(inputField => {
        const sortedOptions = sortOptionsByYesNoOrder(inputField.privacyIssueAnswerInputFieldOptions)

        return (
          <FormControl key={inputField.id}>
            <FormLabel>{inputField.label}</FormLabel>
            <RadioGroup sx={{ paddingLeft: '12px' }}>
              {sortedOptions.map(option => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={(
                    <Radio
                      data-testid={`radio-${option.id}`}
                      checked={answers.includes(option.id)}
                    />
                  )}
                  label={option.internalValue}
                  onChange={() => onChange(inputField.id, option.id)}
                />
              ))}
            </RadioGroup>
            <Box maxWidth='250px' mt={1}><Divider /></Box>
          </FormControl>
        )
      })}
    </Stack>
  )
}
