import React, { useEffect } from 'react'

import { useCurrentOrganization } from '@/hooks/useSession'
import useRouteParams from '@/hooks/useRouteParams'
import useNavigate from '@/hooks/useNavigate'

import { useGetProductTopicsQuery } from '@/services/organization/product/topics'
import { GetTopicsResponseBody } from '@/services/organization/product/topics/types'

import CircularLoading from '@/components/CircularLoading'

function DefaultProduct(): React.ReactElement {
  const navigate = useNavigate()
  const { id: organizationId } = useCurrentOrganization()
  const { productId } = useRouteParams()

  const {
    data: topics,
    isLoading,
  } = useGetProductTopicsQuery(
    {
      params: {
        id: organizationId,
        productId: `${productId}`,
      },
    },
    { refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    if (isLoading || !topics) {
      return
    }

    const { data } = topics as GetTopicsResponseBody

    if (!data || !data.topics.length) {
      return
    }
    if (data.lastVisitedProductPrivacyIssue) {
      if (data.lastVisitedProductPrivacyIssue.privacyIssue.privacyTopic.code === '0.0.0') {
        navigate(`/wizard/products/${productId}/jurisdictionalQuestions`)
      }
      else {
        navigate(`/wizard/products/${productId}/topics/${data.lastVisitedProductPrivacyIssue.privacyIssue.privacyTopic.id}/questions/${data.lastVisitedProductPrivacyIssue.id}`)
      }
    }
    else {
      navigate(`/wizard/products/${productId}/topics/${data.topics[0].id}`)
    }
  }, [
    navigate,
    isLoading,
    topics,
    productId,
  ])

  return <CircularLoading />
}

export default React.memo(DefaultProduct)
