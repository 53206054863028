import React from 'react'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
} from '@mui/material'

import {
  FormikState, useField,
} from 'formik'

import { PrivacyIssueAnswerInputField } from '@/screens/Wizard/components/PrivacyIssueQuestion/types'

type Props = {
    inputFields: PrivacyIssueAnswerInputField[],
    name: string,
}

export default function SheetRadioGroup({
  inputFields,
  name,
  touched,
  errors,
  layout = 'vertical',
}: Props & Partial<Pick<FormikState<Record<string, string[]>>, 'errors' | 'touched'>> & {layout?: 'vertical' | 'horizontal'}) {
  const [
    field,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _meta,
    helpers,
  ] = useField(name)

  const handleChange = (event: React.SyntheticEvent, selectedValue: string) => {
    helpers.setValue([ selectedValue ])
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <RadioGroup
        name={name}
        value={field.value[0] ?? ''}
        sx={{
          display: 'flex',
          flexDirection: layout === 'horizontal' ? 'column' : 'column',
          '& .MuiFormControlLabel-root': { margin: layout === 'horizontal' ? '0 0 4px 0' : undefined },
        }}
        onChange={handleChange}
      >
        {inputFields.flatMap((inputField: PrivacyIssueAnswerInputField) => inputField.privacyIssueAnswerInputFieldOptions.map(option => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={(
              <Radio
                data-testid={`radio-${option.id}`}
                checked={field.value.includes(option.id)}
                size='small'
              />
            )}
            label={option.value}
          />
        )))}
      </RadioGroup>
      {touched && touched[name] && errors && errors[name] && (
        <FormHelperText error={true}>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  )
}
