import React from 'react'
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { isProduction } from '@common-sense-privacy/common'

import Error402 from '@/screens/Error402'
import Error403 from '@/screens/Error403'
import Error404 from '@/screens/Error404'
import Error500 from '@/screens/Error500'

import DevScratch from '@/screens/DevScratch'
import Home from '@/screens/Home'
import Dashboard from '@/screens/Dashboard'
import DashboardTopPrivacyIssues from '@/screens/Dashboard/TopPrivacyIssues'
import DashboardPrivacyIssueDetail from '@/screens/Dashboard/PrivacyIssueDetail'
import DashboardLegallySpeaking from '@/screens/Dashboard/LegallySpeaking'
import DashboardLegallySpeakingDetail from '@/screens/Dashboard/LegallySpeaking/Detail'
import DashboardRaiseYourScore from '@/screens/Dashboard/RaiseYourScore'
import DashboardResources from '@/screens/Dashboard/Resources'
import DashboardResourcesDetail from '@/screens/Dashboard/Resources/Detail'
import DashboardBenchmarking from '@/screens/Dashboard/Benchmarking'

import SignUp from '@/screens/SignUp'
import SignIn from '@/screens/SignIn'

import ForgotPassword from '@/screens/SignIn/ForgotPassword'
import ResetPassword from '@/screens/SignIn/ForgotPassword/ResetPassword'

import AccountVerify from '@/screens/Account/Verify'
import Account from '@/screens/Account'
import EditContact from '@/screens/Account/EditContact'
import EditOrganization from '@/screens/Account/EditOrganization'
import EditPassword from '@/screens/Account/EditPassword'
import EditNotifications from '@/screens/Account/EditNotifications'
import EditProduct from '@/screens/Account/EditProduct'
import EditPlan from '@/screens/Account/EditPlan'
import EditPlanPayment from '@/screens/Account/EditPlan/Payment'
import EditPaymentMethod from '@/screens/Account/EditPayment'

import Verify from '@/screens/Verify'

// import PrivacyPolicy from '@/screens/Wizard/Product/PrivacyPolicy'
// import PrivacyPolicyUrl from '@/screens/Wizard/Product/PrivacyPolicy/Url'

import ProductInformation from '@/screens/Wizard/Product/Information'

import ContactSupport from '@/screens/Contact/Support'

import Wizard from '@/screens/Wizard'
import WizardStart from '@/screens/Wizard/WizardStart'
import WizardProducts from '@/screens/Wizard/Products'
import WizardDefaultProduct from '@/screens/Wizard/Products/DefaultProduct'
import PrivacyTopic from '@/screens/Wizard/Products/Topics'
import ProductPrivacyIssue from '@/screens/Wizard/Products/Topics/ProductPrivacyIssue'
import WizardFinalizeSubmit from '@/screens/Wizard/Products/Finalize'
import PrivacyPolicyList from '@/screens/Wizard/PrivacyPolicyList'

import LegalPrivacyPolicy from '@/screens/Legal/Privacy'
import LegalTermsConditions from '@/screens/Legal/Terms'

import SubscriptionsPlans from '@/screens/Subscriptions/Plans'
import SubscriptionsPayment from '@/screens/Subscriptions/Payment'

import JurisdictionalQuestions from '@/screens/Wizard/Products/Topics/JurisdictionalQuestions'

import RequireSignIn from './RequireSignIn'

import ProtectedRoute, {
  hasProduct,
  requiresWizardPlan,
  requiresDashboardPlan,
} from './ProtectedRoute'

export { default as history } from './history'

function Router(): React.ReactElement {
  return (
    <Routes>
      <Route path='/dev/scratch' element={isProduction ? <Error404 /> : <DevScratch />} />

      <Route path='/' element={<Home />} />

      <Route path='/signup' element={<SignUp />} />
      <Route path='/signin' element={<SignIn />} />
      <Route
        path='/forgot-password'
        element={(
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY || ''}>
            <ForgotPassword />
          </GoogleReCaptchaProvider>
        )}
      />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/signup/verify' element={<AccountVerify />} />
      <Route path='/verify' element={<Verify />} />
      <Route
        path='/account'
        element={(
          <RequireSignIn />
        )}
      >
        <Route path='' element={<Account />} />
        <Route path='/account/edit-contact' element={<EditContact />} />
        <Route path='/account/edit-organization' element={<ProtectedRoute otherwise={[ <Error403 key='forbidden' /> ]}><EditOrganization /></ProtectedRoute>} />
        <Route path='/account/edit-password' element={<EditPassword />} />
        <Route path='/account/edit-product' element={<EditProduct />} />
        <Route path='/account/edit-notifications' element={<EditNotifications />} />
        <Route path='/account/edit-plan' element={<EditPlan />} />
        <Route path='/account/edit-plan/payment' element={<EditPlanPayment />} />
        <Route path='/account/edit-payment-method' element={<EditPaymentMethod />} />
      </Route>

      <Route path='/contact-support' element={<ContactSupport />} />

      <Route
        path='/dashboard'
        element={(
          <RequireSignIn>
            <ProtectedRoute test={[ requiresDashboardPlan ]} />
          </RequireSignIn>
        )}
      >
        <Route path='' element={<Dashboard key='dashboard' />} />
        <Route
          path='top-privacy-issues'
          element={<DashboardTopPrivacyIssues key='dashboard' />}
        />

        <Route path='legally-speaking' element={<RequireSignIn />}>
          <Route path='' element={<DashboardLegallySpeaking key='dashboard' />} />
          <Route
            path=':lawId'
            element={<DashboardLegallySpeakingDetail key='dashboardLegallySpeakingDetail' />}
          />
        </Route>

        <Route path='raise-your-score' element={<DashboardRaiseYourScore key='dashboard' />} />
        <Route
          path='privacy-issues/:privacyIssueId'
          element={<DashboardPrivacyIssueDetail key='dashboard' />}
        />
        <Route path='resources' element={<DashboardResources />} />
        <Route path='resources/:id' element={<DashboardResourcesDetail />} />
        <Route path='benchmarking' element={<DashboardBenchmarking />} />
      </Route>
      <Route path='resources/:id' element={<DashboardResourcesDetail preview={true} />} />
      <Route
        path='/wizard'
        element={(
          <RequireSignIn />
        )}
      >
        <Route
          path=''
          element={(
            <ProtectedRoute
              test={[ hasProduct ]}
              otherwise={[ <Navigate key='productPrivacyPolicy' to='/wizard/product/information' /> ]}
            ><Wizard />
            </ProtectedRoute>
          )}
        />
        <Route path='product'>
          {/* <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='privacy-policy/url' element={<PrivacyPolicyUrl />} /> */}
          <Route path='information' element={<ProductInformation />} />
        </Route>
        <Route
          path='products/:productId'
          element={<WizardProducts />}
        >
          <Route index={true} element={<WizardDefaultProduct />} />
          <Route path='topics/:topicId' element={<PrivacyTopic />} />
          <Route path='finalize' element={<WizardFinalizeSubmit />} />
          <Route path='topics/:topicId/questions/:questionId' element={<ProductPrivacyIssue />} />
          <Route path='jurisdictionalQuestions' element={<JurisdictionalQuestions />} />
          <Route path='*' element={<Error404 />} />
        </Route>
        <Route path='start' element={<WizardStart />} />
      </Route>

      <Route
        path='/privacy-policies'
        element={(
          <RequireSignIn>
            <ProtectedRoute
              test={[
                requiresWizardPlan,
                hasProduct,
              ]}
              otherwise={[
                <Error403 key='error403' />,
                <Navigate key='productPrivacyPolicy' to='/wizard/product/information' />,
              ]}
            ><PrivacyPolicyList />
            </ProtectedRoute>
          </RequireSignIn>
        )}
      />

      <Route
        path='/subscriptions'
        element={(<RequireSignIn />)}
      >
        <Route path='plans' element={<SubscriptionsPlans />} />
        <Route path='payment' element={<SubscriptionsPayment />} />
      </Route>

      <Route path='/402' element={<Error402 />} />
      <Route path='/403' element={<Error403 />} />
      <Route path='/500' element={<Error500 />} />
      <Route path='*' element={<Error404 />} />

      <Route path='/privacy' element={<LegalPrivacyPolicy />} />
      <Route path='/terms' element={<LegalTermsConditions />} />

    </Routes>
  )
}

export default Router
