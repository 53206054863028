import React from 'react'

import {
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useNavigate } from 'react-router-dom'

import { lang } from '@common-sense-privacy/common'

import {
  useGetJurisdictionalQuestionsQuery, usePutJurisdictionalQuestionsMutation,
} from '@/services/organization/product'

import { useGetProductTopicsQuery } from '@/services/organization/product/topics'
import useRouteParams from '@/hooks/useRouteParams'
import { useCurrentOrganization } from '@/hooks/useSession'

import QuestionsContactSupport from '@/components/QuestionsContactSupport'
import CircularLoading from '@/components/CircularLoading'

import PrivacyIssueSheet from '@/components/PrivacyIssueSheet'
import { useAlert } from '@/context/AlertContext'

function JurisdictionalQuestions() {
  const { id: organizationId } = useCurrentOrganization()
  const navigate = useNavigate()
  const { productId } = useRouteParams()
  const { setAlert } = useAlert()

  const {
    data: { data: jurisdictionalProductPrivacyIssues } = {},
    isFetching,
  } = useGetJurisdictionalQuestionsQuery(
    {
      params: {
        id: organizationId,
        productId: `${productId}`,
      },
    },
    { refetchOnMountOrArgChange: true },
  )

  const jurisQuestions = jurisdictionalProductPrivacyIssues?.jurisdictionalProductPrivacyIssues

  const {
    jurisTopicTitle,
    jurisTopicId,
    nextTopicId,
  } = useGetProductTopicsQuery({
    params: {
      id: organizationId,
      productId: `${productId}`,
    },
  }, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => {
      if (!data?.data?.topics || !jurisQuestions) return {}

      const jurisTopic = data.data.topics.find(topic => topic.code === '0.0.0')
      const nextTopic = data.data.topics.find(topic => topic.code === '1.0.0')

      return {
        jurisTopicTitle: jurisTopic?.title,
        jurisTopicId: jurisTopic?.id,
        nextTopicTitle: nextTopic?.title,
        nextTopicId: nextTopic?.id,
      }
    },
  })

  const [
    putMultipleProductPrivacyIssuesMutation,
    { isLoading: isSubmitting },
  ] = usePutJurisdictionalQuestionsMutation()

  const submitAnswers = async (answers: Record<string, string[]>) => {
    putMultipleProductPrivacyIssuesMutation({
      body: {
        privacyIssues: Object.fromEntries(Object.entries(answers).map(([
          k,
          v,
        ]) => ([
          k,
          { answers: v },
        ]))),
      },
      params: {
        id: organizationId,
        productId: `${productId}`,
      },
    })
      .unwrap()
      .then(() => {
        navigate(`/wizard/products/${productId}/topics/${nextTopicId}`)
      }).catch(() => {
        setAlert({
          description: lang().messages.somethingWentWrong(),
          type: 'error',
        })
      })
  }

  const handleOnBack = () => {
    navigate(`/wizard/products/${productId}/topics/${jurisTopicId}`)
  }

  if (!jurisQuestions || isFetching) {
    return <CircularLoading />
  }

  return (
    <>
      <Container>
        <Button
          onClick={handleOnBack}
          variant='link'
          data-testid='privacy-issues-back'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'text.primary',
            textDecoration: 'none',
            fontWeight: 'bold',
            left: '-8px',
          }}
        >
          <KeyboardArrowLeftIcon /> Back
        </Button>

        <Typography variant='h3' mt={4}>{jurisTopicTitle}</Typography>

        <Stack spacing={4} mt={4}>
          <PrivacyIssueSheet
            isSubmitting={isSubmitting}
            saveButtonText='Save & Continue'
            onSave={submitAnswers}
            productPrivacyIssues={jurisQuestions}
          />
        </Stack>
      </Container>
      <Container>
        <Stack mt={8} spacing={4}>
          <Divider />
          <QuestionsContactSupport />
        </Stack>
      </Container>
    </>
  )
}

export default React.memo(JurisdictionalQuestions)
