/* eslint-disable no-console */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import {
  Box,
  Button, Tooltip, Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

import rules from '@common-sense-privacy/common/src/validation/rules'

import { ProductPrivacyIssue } from '@/utils/recommendedAction/types'

import Form from '../Form'

import SheetRadioGroup from './SheetRadioGroup'

function PrivacyIssueSheet({
  isSubmitting = false,
  onSave,
  productPrivacyIssues,
  saveButtonText = 'Save',
} : {
    isSubmitting: boolean,
    onSave: (privacyIssues: Record<string, Array<string>>) => void,
    productPrivacyIssues: Array<ProductPrivacyIssue>,
    saveButtonText?: string,
  }): React.ReactElement {
  const initialAnswers = Object.fromEntries(productPrivacyIssues.map(e => [
    e.id,
    e.answers?.map(ans => ans?.answer.id) ?? [],
  ]))

  const formRules = Object.fromEntries(productPrivacyIssues.map(e => [
    e.id,
    rules.array()
      .required('* This question is required')
      .min(1, '* This question is required'),
  ]))

  return (
    <Stack spacing={4} mt={4}>
      <Form
        enableReinitialize={false}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={values => {
          onSave(values)
        }}
        initialValues={initialAnswers}
        rules={formRules}
      >
        {({
          errors, touched,
        }) => (
          <>
            {productPrivacyIssues?.map((issue, issueNo) => {
              const {
                id, privacyIssue,
              } = issue

              const { infoTips } = privacyIssue

              return (
                <Box
                  key={id}
                  pb={3}
                  sx={{
                    display: 'flex',
                    py: 2,
                  }}
                >
                  <Box sx={{
                    flex: 1,
                    pr: 2,
                  }}
                  >
                    <Typography color='black' variant='body1'>
                      <strong>{issueNo + 1}. {privacyIssue.question}</strong>
                    </Typography>
                  </Box>
                  <Box sx={{
                    width: '150px',
                    borderLeft: '1px solid #eee',
                    pl: 2,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <SheetRadioGroup
                        inputFields={privacyIssue?.answerInputFields}
                        name={issue.id}
                        errors={errors}
                        touched={touched}
                        layout='horizontal'
                      />
                    </Box>
                    {!!infoTips && (
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml: 1,
                      }}
                      >
                        <Tooltip
                          placement='top'
                          enterTouchDelay={0}
                          PopperProps={{
                            disablePortal: true,
                            sx: {
                              '& .MuiTooltip-tooltip': {
                                height: '100%',
                                maxHeight: '150px',
                                overflow: 'auto',
                              },
                            },
                          }}
                          title={<Box dangerouslySetInnerHTML={{ __html: infoTips }} />}
                        >
                          <InfoOutlinedIcon color='inherit' />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Box>
              )
            })}
            <Stack direction='row' spacing={2} mt={2}>
              <Button
                sx={{ width: 'auto' }}
                type='submit'
                disabled={isSubmitting}
              >{isSubmitting ? 'Saving...' : saveButtonText}
              </Button>
            </Stack>
          </>
        )}
      </Form>
    </Stack>
  )
}

export default React.memo(PrivacyIssueSheet)
