import validation from '../../../../../validation'
import rules from '../../../../../validation/rules'

const newPutBodyRules = {
  privacyIssues: rules.object().test(
    'valid-answers-object',
    'Invalid answers object structure',
    obj => {
      if (!obj) return false

      return Object.values(obj).every(value => {
        if (!value || typeof value !== 'object') return false

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!Array.isArray((value as Record<string, any>).answers)) return false

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (value as Record<string, any>).answers.every((answer: any) => typeof answer === 'string')
      })
    },
  ) as rules.ObjectSchema<Record<string, { answers: string[] }>>,
}

const putParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
}

const putParams = {
  rules: putParamRules,
  schema: validation.schema(putParamRules),
}

const putBody = {
  rules: newPutBodyRules,
  schema: validation.schema(newPutBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const put = {
  body: putBody,
  params: putParams,
}

export const get = { params: putParams }
