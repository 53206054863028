/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import {
  Box,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'

import { Container } from '@mui/system'

import { url } from '@common-sense-privacy/common'

import { useLocation } from 'react-router-dom'

import ChoosePlan from '@/components/ChoosePlan'

import {
  useOrganizationSubscription,
  useAuthInfo,
} from '@/hooks/useSession'

import Version from '../../Version'

function Footer(): React.ReactElement {
  const subscription = useOrganizationSubscription()
  const isFreePlan = subscription?.items.data.some(item => item.plan.amount === 0)
  const { pathname } = useLocation()
  const { isLoggedInAndVerified } = useAuthInfo()
  const [
    isWizard,
    setIsWizard,
  ] = React.useState(false)

  useEffect(() => {
    if (pathname.match('wizard')) {
      setIsWizard(true)
    }
  }, [ pathname ])

  return (
    <>
      {(isWizard && isFreePlan) && (<ChoosePlan />)}
      <Stack
        marginTop='auto'
        alignItems='center'
        justifyContent='center'
        width='100%'
        py={1.5}
        sx={{ backgroundColor: '#D9D9D9' }}
      >
        <Container maxWidth='md'>
          <Stack alignItems='center' justifyContent='center' mb={1} direction='row' width='100%'><Typography fontSize='14px' mr={0.5}>Ⓒ</Typography><Typography variant='body2'>Common Sense Privacy {new Date().getFullYear()}</Typography> <Divider
            orientation='vertical'
            variant='inset'
            sx={{
              height: '13px',
              borderWidth: '1px',
              borderColor: 'black',
              alignItems: 'center',
              marginLeft: '5px',
              marginRight: '5px',
            }}
          />
          <Typography variant='body2' textAlign='center'>
            <Stack direction='row' alignItems='center' justifyContent='center'>
              <Link href={url.marketingSite('/legal/application-privacy-policy')} color='inherit' target='_blank' rel='noreferrer'>Privacy Policy</Link><Divider
                orientation='vertical'
                variant='inset'
                sx={{
                  height: '13px',
                  borderWidth: '1px',
                  borderColor: 'black',
                  alignItems: 'center',
                  marginLeft: '5px',
                  marginRight: '5px',
                  width: '0px',
                }}
              /><Link href={url.marketingSite('/legal/terms-of-service')} color='inherit' target='_blank' rel='noreferrer'>Terms and Conditions</Link>
            </Stack>
          </Typography>
          </Stack>
          {isLoggedInAndVerified
        && <Typography fontSize={11} color='text.secondary' textAlign='center'>NO LEGAL ADVICE. Partner understands and agrees that Common Sense Privacy is not a law firm and is not providing Partner legal advice or representation. Common Sense Privacy’s rating of Partner’s privacy policy is its subjective evaluation based on its general understanding and review of industry standards and practices. Common Sense Privacy’s rating is not a legal opinion, should not be treated as a legal opinion by Partner or any other party, and is not a substitute for the opinion or advice of an attorney.</Typography> }
          <Box fontSize='11px' textAlign='center'><Version /></Box>
        </Container>
      </Stack>
    </>
  )
}

export default React.memo(Footer)
